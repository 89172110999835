import NextHead from 'next/head'
import { FunctionComponent, ReactComponentElement } from 'react'

const Head: FunctionComponent = ({
  children,
}: {
  children?: React.ReactNode
}): ReactComponentElement<typeof NextHead> => {
  return (
    <NextHead>
      <title>Fitify Coach Admin</title>
      <meta name="description" content={'Fitify Coach Admin'} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="og:type" content="website" />
      <meta property="og:image" content="/images/og.jpg" />

      {/*TODO: PUT PROBABLY ENV VARIABLE HERE?*/}
      {typeof window !== 'undefined' && (
        <meta
          property="og:url"
          content={location.protocol + '//' + location.hostname}
        />
      )}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      {children}
    </NextHead>
  )
}

export default Head
