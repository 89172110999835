import styled from 'styled-components'

interface Props {
  className?: string
  width?: number
  height?: number
  top?: number | string
  left?: number | string
  middle?: boolean
  center?: boolean
  area?: string
}

const Cell = styled.div<Props>`
  grid-row-end: ${({ height = 1 }) => `span ${height}`};
  grid-column-end: ${({ width = 1 }) => `span ${width}`};
  min-width: 0;
  height: 100%;
  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};
  ${({ center }) => center && `text-align: center`};
  ${({ area }) => area && `grid-area: ${area}`};
  ${
    /* prettier-ignore */
    ({ middle }) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `
  };
`

export default Cell
