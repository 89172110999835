import { colors } from 'fitify-ui'
import styled from 'styled-components'

export type StyledLoaderProps = {
  isLoading: boolean
  variant?: 'white' | 'gray'
}

export const StyledLoader = styled.div<StyledLoaderProps>`
  position: absolute;
  inset: 0;
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'gray':
        return colors.Gray100
      case 'white':
      default:
        return colors.White
    }
  }};
  opacity: 0.7;
`
