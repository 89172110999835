import styled, { css } from 'styled-components'

import { FieldWrapperProps } from '@/components/forms/Field.Types'

export const FieldWrapper = styled.div<FieldWrapperProps>`
  display: flex;
  gap: 4px;
  width: 100%;

  ${({ direction }) => {
    switch (direction) {
      case 'row': {
        return css`
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        `
      }
      case 'column':
      default: {
        return css`
          flex-direction: column;
        `
      }
    }
  }};
`

export const FieldInputAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
