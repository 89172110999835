import React, { CSSProperties } from 'react'
import { type FieldError as FieldErrorType } from 'react-hook-form'

import Text from '@/components/core/Text/Text'

type Props = {
  error?: FieldErrorType
  style?: CSSProperties
}

export const FieldError = ({ error, style }: Props) => {
  if (error) {
    return (
      <Text as="div" variant="xs" color="Red400" style={style}>
        {error.message}
      </Text>
    )
  }
  return null
}
