import styled from 'styled-components'

const autoRows = ({ minRowHeight = '20px' }) => `minmax(${minRowHeight}, auto)`

const frGetter = (value: number | string) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : value

const gap = ({ gap = '8px' }) => gap

const flow = ({ flow = 'row' }) => flow

const formatAreas = (areas: string[]) =>
  areas.map((area) => `"${area}"`).join(' ')

interface Props {
  className?: string
  columns?: number | string
  gap?: string
  columnGap?: string
  rowGap?: string
  height?: string
  minRowHeight?: string
  flow?: string
  rows?: number | string
  areas?: string[]
  justifyContent?: string
  alignContent?: string
}

const Grid = styled.div<Props>`
  display: grid;
  grid-auto-rows: ${autoRows};
  grid-auto-flow: ${flow};
  ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};
  grid-gap: ${gap};
  grid-template-columns: ${({ columns = 12 }) => frGetter(columns)};
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap}`};
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};
  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  height: ${({ height = 'auto' }) => height};
`
export default Grid
