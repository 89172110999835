import { parseFloatNumber } from 'fitify-utils/src/properties'
import React from 'react'
import { useController } from 'react-hook-form'

import {
  FieldInputAndErrorWrapper,
  FieldWrapper,
} from '@/components/forms/Field.Styled'
import { FieldError } from '@/components/forms/FieldError'
import { FieldLabel } from '@/components/forms/FieldLabel'
import { InputFieldProps } from '@/components/forms/Input/Input.Types'

import { Input } from './Input'

export const InputField = ({
  className,
  control,
  direction = 'column',
  icon,
  isRequired = false,
  label,
  name,
  onBlur: propsOnBlur,
  onChange: propsOnChange,
  rules,
  suffix,
  type,
  variant,
  variantSize,
  ...htmlProps
}: InputFieldProps) => {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur()
    propsOnBlur?.(event)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      type === 'number'
        ? parseFloatNumber(event.target.value)
        : event.target.value
    onChange({ ...event, target: { ...event.target, value } })
    propsOnChange?.(event)
  }

  const id = `input-${name}`

  return (
    <FieldWrapper className={className} direction={direction}>
      {label && (
        <FieldLabel label={label} labelFor={id} isRequired={isRequired} />
      )}
      <FieldInputAndErrorWrapper>
        <Input
          {...htmlProps}
          data-testid={id}
          id={id}
          icon={icon}
          hasError={Boolean(error)}
          onBlur={handleBlur}
          onChange={handleChange}
          suffix={suffix}
          type={type}
          value={value}
          variant={variant}
          variantSize={variantSize}
        />
        <FieldError error={error} />
      </FieldInputAndErrorWrapper>
    </FieldWrapper>
  )
}
