import React from 'react'

import { Loader } from '@/components/core/Loader/Loader'
import {
  StyledLoader,
  StyledLoaderProps,
} from '@/components/core/Loader/Loader.Styled'

type Props = StyledLoaderProps

export const FullScreenLoader = ({ isLoading, variant }: Props) => {
  return (
    <StyledLoader isLoading={isLoading} variant={variant}>
      <Loader size={48} />
    </StyledLoader>
  )
}
